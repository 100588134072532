import React from "react";
import { graphql, Link } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "./../components/Seo";


export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        author
        image {
          name
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              layout: FULL_WIDTH
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`;

const shortcodes = { Link };

const PoemTemplate = ({ data: { mdx } }) => {
  // const img = getImage(image.childImageSharp.gatsbyImageData)
  console.log(mdx)
  return (
    <section className="overflow-hidden text-gray-600 body-font">
      <Seo title={mdx.frontmatter.title} />
      <div className="container py-24 mx-auto">
        <div className="flex flex-wrap mx-auto lg:w-4/5">
          {/* <GatsbyImage
            image={getImage(mdx.frontmatter.image)}
            alt={mdx.frontmatter.image.name}
            className="object-cover object-center w-full h-64 rounded lg:w-1/2 lg:h-auto"
          /> */}
          <article className="w-full lg:pl-10 lg:py-6 lg:mt-0">
            <h1 className="mb-1 text-3xl font-medium text-gray-900 title-font">
              {mdx.frontmatter.title}
            </h1>
            <h2 className="mb-3 text-sm tracking-widest text-gray-500 title-font">
              {mdx.frontmatter.author}
            </h2>
            <div className="leading-relaxed">
              <MDXProvider components={shortcodes}>
                <MDXRenderer frontmatter={mdx.frontmatter}>
                  {mdx.body}
                </MDXRenderer>
              </MDXProvider>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default PoemTemplate;

/*

<section className="text-gray-600 body-font">
  <div className="container flex flex-wrap px-5 py-24 mx-auto">
    <div className="flex flex-wrap -m-4">
      <div className="p-4 lg:w-1/2 md:w-full">
        <div className="flex flex-col p-8 border-2 border-gray-200 border-opacity-50 rounded-lg sm:flex-row">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 mb-4 text-indigo-500 bg-indigo-100 rounded-full sm:mr-8 sm:mb-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
          </div>
          <div className="flex-grow">
            <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">Shooting Stars</h2>
            <p className="text-base leading-relaxed">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
            <a className="inline-flex items-center mt-3 text-indigo-500">Learn More
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className="p-4 lg:w-1/2 md:w-full">
        <div className="flex flex-col p-8 border-2 border-gray-200 border-opacity-50 rounded-lg sm:flex-row">
          <div className="inline-flex items-center justify-center flex-shrink-0 w-16 h-16 mb-4 text-indigo-500 bg-indigo-100 rounded-full sm:mr-8 sm:mb-0">
            <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
              <circle cx="12" cy="7" r="4"></circle>
            </svg>
          </div>
          <div className="flex-grow">
            <h2 className="mb-3 text-lg font-medium text-gray-900 title-font">The Catalyzer</h2>
            <p className="text-base leading-relaxed">Blue bottle crucifix vinyl post-ironic four dollar toast vegan taxidermy. Gastropub indxgo juice poutine.</p>
            <a className="inline-flex items-center mt-3 text-indigo-500">Learn More
              <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

*/
